exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-common-page-js": () => import("./../../../src/templates/common-page.js" /* webpackChunkName: "component---src-templates-common-page-js" */),
  "component---src-templates-event-coda-js": () => import("./../../../src/templates/event-coda.js" /* webpackChunkName: "component---src-templates-event-coda-js" */),
  "component---src-templates-event-list-js": () => import("./../../../src/templates/event-list.js" /* webpackChunkName: "component---src-templates-event-list-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/news-item.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-pricing-js": () => import("./../../../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */)
}

